<script lang="ts">

    import { browser, version } from "$app/environment";
	import { goto } from "$app/navigation";
	import settings_store from "$lib/client/indexeddb/settings_store";
	import env_client from "$lib/env/env_clientside";
	import type { AndroidPushNotificationChannel } from "$lib/native_constants";
	import { notification_store } from "$lib/stores/notification_store";
	import type { PermissionStatus } from "@capacitor-firebase/messaging";
	import { Capacitor } from "@capacitor/core";
    import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
    import { App } from '@capacitor/app';
    import { StatusBar, Style } from '@capacitor/status-bar';
    import { FirebaseMessaging } from '@capacitor-firebase/messaging';
    import { SplashScreen } from '@capacitor/splash-screen';
	import analytics from "$lib/client/analytics";
	import { page } from "$app/stores";
	import type { UTMTracking } from "$lib/client/entity_utils";
	import { user_session } from "$lib/stores/user_session_store";
	import { log_error } from "$lib/client/logger_client";
	import { onMount } from "svelte";
	import { sleep } from "$lib/utils";
    import { Browser } from '@capacitor/browser';
    import "cordova-plugin-purchase/www/store.d"
	import { client_subscription_service } from "$lib/client/subscription_utils";

    let user_session_store = user_session();
    let {user_settings, user_account_uuid} = $user_session_store;
    let user_logged_in = user_settings?.enabled ?? false;

    let is_ios = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
    let is_android = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android';

    async function initialize_deep_linking_support() {

        try {

            // App.addListener('appStateChange', ({ isActive }) => {
            //     console.log('App state changed. Is active?', isActive);
            // });

            // App.addListener('appRestoredResult', (result) => {
            //     console.log("App restored result: ", result);
            // });

            App.addListener('appUrlOpen', (data) => {
                
                // alert("Received this: " + data.url);

                let target_url;

                let url_scheme = data.url.split('://')[0];
                if (url_scheme === env_client.app_url_scheme()) {

                    // close in-app-browser used by iOS
                    // let is_ios = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
                    // if (is_ios) {

                        try {

                            Browser.close();
                            // alert("Closed browser window with URL: " + data.url);

                        } catch (error) {
                            notification_store.notify_message("Unable to close login browser", JSON.stringify(error));
                        }
                    // }

                    let target_path_and_query = data.url.split('://')[1];

                    target_url = new URL(target_path_and_query, env_client.www_endpoint());

                } else {

                    target_url = new URL(data.url);
                }

                if (target_url.hostname !== env_client.app_hostname()) {
                    notification_store.notify_message("Invalid URL", 
                                                      "The app destination you are trying to reach is not a valid destination.");
                    return;
                }

                window.location.href = target_url.toString();
            });

            App.addListener('resume', () => {
                console.log("Resume detected re-initializing status bar support.");
                initialize_status_bar_support();
            });

        } catch (error) {
            console.error("Unable to initialize plugin 'App': ", error);
        }
    }

    async function initialize_status_bar_support() {

        try {

            /*
            * Status bar support
            */
            const setStatusBarStyle = async () => {

                await StatusBar.setStyle({ style: Style.Dark });
                await StatusBar.setBackgroundColor({color: "#18181B"});
                await StatusBar.hide();

                if (Capacitor.getPlatform() === 'android') { 

                    // only supported in 'android'

                    /*
                    * Currently WebView does not define any support for notch inset styles. 
                    * WebView is meant to be a restricted window where web content can be rendered,
                    * so it is missing the notch-environment variables that browsers provide.
                    * 
                    * Below, the code is manually setting padding to account for this deficiency
                    * when overlay is set to 'true'.
                    * 
                    * This doesn't impact iOS, amazingly enough, as they do supply it to WebViews.
                    */
                    await StatusBar.setOverlaysWebView({ overlay: true });
                    let nodes: HTMLCollection = document.getElementsByClassName("safe-top");
                    for (let i = 0; i < nodes.length; i++) {
                        let node: any = nodes[i];
                        node.style.paddingTop='2rem';
                    }
                }
            };

            // const hideStatusBar = async () => {
            //     await StatusBar.hide();
            // };

            // const showStatusBar = async () => {
            //     await StatusBar.show();
            // };                

            await setStatusBarStyle();
        } catch (error) {
            console.error("Unable to initialize plugin 'StatusBar': ", error);
        }
    }

    async function initialize_push_notification_support() {

        const register_token = async (token: string) => {

            let form_data = new FormData();
            form_data.set('registration_token', token);

            fetch("/push_notifications.json", 
                {
                    method: 'POST',
                    body: form_data
                }).then( (result) => {

                //console.log("Result from POST: ", result);

                if (result.status == 200) {
                    console.log("Successful push notification token registration.");
                }
            });
        };

        /*
        * Push notification support.
        */
        const addPushNotificationListeners = async () => {

            await FirebaseMessaging.addListener('notificationReceived', (event) => {
                console.log('Push notification received: ', JSON.stringify(event));
                let notification = event.notification;
                notification_store.notify_message(notification.title ?? "Swimerize Notification", notification.body ?? "");
            });

            await FirebaseMessaging.addListener('notificationActionPerformed', (event: any) => {

                console.log('Push notification action performed', JSON.stringify(event));

                let {channel_id, path} = event.notification.data;
                if (path === undefined) {
                    return;
                }
                
                let target_url = new URL(path, env_client.www_endpoint());
                goto(target_url);
            });
            
            await FirebaseMessaging.addListener('tokenReceived', async event => {
                // console.log('tokenReceived', { event });
                // alert(`Token received: ${event.token}`);
                await register_token(event.token);
            });            
        };

        const registerPushNotifications = async () => {

            let permission_status: PermissionStatus = await FirebaseMessaging.checkPermissions();
            if (permission_status.receive === 'prompt') {
                permission_status = await FirebaseMessaging.requestPermissions();
            }

            if (permission_status.receive !== 'granted') {
                console.warn("Push notification registration not granted: ", permission_status.receive);
                notification_store.notify_message('Swimerize Notice', 'You have disabled push notifications. To receive notifications of Swimerize activity, please grant push notifications permission next time you start up the Swimerize App.')
                return;
            }

            // get token
            try {
                const result = await FirebaseMessaging.getToken();
                await register_token(result.token);
                // let token = result.token;
                // alert(`getToken(): ${token}`);
            } catch (error) {

                console.warn("Unable to get push notification token at startup: ", error);
                // notification_store.notify_message('Push Notifications Error', `We are unable to enable push notifications. Error: ${error}`);
            }
        };

        const create_channels = async () => {

            if (Capacitor.getPlatform() !== 'android') {
                return;
            }

            const channels: {id: AndroidPushNotificationChannel, name: string, description: string}[] = 
                [   
                    {
                        id: 'pools',
                        name: 'Pool Swim Uploads',
                        description: 'Notifications about pool sessions added to your network.',
                    },
                    {
                        id: 'tracks',
                        name: 'Open Water Swim Uploads',
                        description: 'Notifications about open water swims added to your network.',
                    },
                    {
                        id: 'discussions',
                        name: 'Discussions',
                        description: 'Notifications about discussions added by people you follow.',
                    },
                    {
                        id: 'comments',
                        name: 'Comments and Responses',
                        description: 'Notifications about responses or comments added to content you shared.',
                    },
                    {
                        id: 'groups',
                        name: 'Group Membership Events',
                        description: 'Notifications pertaining to group invites or events you should review if you are a group admin.',
                    },
                    {
                        id: 'reactions',
                        name: 'Reactions to Your Content',
                        description: 'Reactions from your network on content you have shared.',
                    },
                    {
                        id: 'admin',
                        name: 'Account Events',
                        description: 'Notifications pertaining to account setup or other miscellaneous actions you should take on your account.',
                    }
                ];

            for (let channel of channels) {
                await FirebaseMessaging.createChannel(channel);
            }
        };         

        // const getDeliveredPushNotifications = async () => {
        //     const notificationList = await PushNotifications.getDeliveredNotifications();
        //     console.log('delivered notifications', notificationList);
        // } 

        let is_plugin_available = false;
        try {

            let is_plugin_available = Capacitor.isPluginAvailable("FirebaseMessaging");
            if (!is_plugin_available) {

                throw new Error("FirebaseMessaging plugin is not available on native device.");
            }

            await addPushNotificationListeners();
            await registerPushNotifications();
            await create_channels();

        } catch (error: any) {
            log_error('error', 
                      'native_plugins', 
                      {type: 'error', error}, 
                      version, 
                      undefined, 
                      {plugin: 'firebase_messaging', 
                      is_plugin_available});
        }
    }

    async function hide_splash_screen() {

        let is_plugin_available = false;
        let attempts = 0;
        try {

            do {

                is_plugin_available = Capacitor.isPluginAvailable("SplashScreen");
                if (is_plugin_available) {
                    
                    await SplashScreen.hide();
                    console.log("-----------> Splash screen hidden.");
                    break;

                } else {

                    attempts++;
                    await sleep(1000); // pause for a second
                    console.log("-------------> Splash screen not available. Sleeping... ", attempts);
                }

            } while (is_plugin_available === false && attempts < 3);

        } catch (error: any) {

            log_error('error', 
                      'native_plugins', 
                      {type: 'error', error}, 
                      version, 
                      undefined, 
                      {plugin: 'splash_screen', 
                      is_plugin_available});
        }
    }

    async function initialize_analytics() {

        // if (user_logged_in) {
        //     console.log("Logging user uuid.", user_uuid);
        //     await FirebaseAnalytics.setUserId({
        //         userId: user_uuid!,
        //     });
        // }

        // const setUserProperty = async () => {
        // await FirebaseAnalytics.setUserProperty({
        //     key: 'language',
        //     value: 'en',
        // });
        // };

        // const setSessionTimeoutDuration = async () => {
        // await FirebaseAnalytics.setSessionTimeoutDuration({
        //     duration: '120',
        // });
        // };

        // enable analytics
        let settings_approved = await settings_store.get_setting('approved_analytics');
        if (settings_approved === undefined) {

            await settings_store.put_setting('approved_analytics', {value_boolean: user_settings!.analytics_enabled});
        }

        let plugin_available = Capacitor.isPluginAvailable("FirebaseAnalytics");
        if (user_settings!.analytics_enabled && plugin_available) {

            console.log("Plugin ready [FirebaseAnalytics]: ", Capacitor.isPluginAvailable("FirebaseAnalytics"));
            
            try {

                await FirebaseAnalytics.setEnabled({enabled: true});

                if (user_account_uuid) {
                    await FirebaseAnalytics.setUserId({userId: user_account_uuid});
                    console.log("Initialized user analytics with user id: ", user_account_uuid);
                }
            } catch (error: any) {
                console.error("Unable enable analytics and set user id.", error);
            }

            // log current page
            try {
                let search_parameters = $page.url.searchParams;
                let source = search_parameters.get('utm_source');
                let medium = search_parameters.get('utm_medium');
                let campaign = search_parameters.get('utm_campaign');

                let utm_tracking: UTMTracking | undefined;
                if (source || medium || campaign) {
                    utm_tracking = 
                        {
                            utm_source: source !== null ? source : undefined, 
                            utm_medium: medium !== null ? medium : undefined, 
                            utm_campaign: campaign !== null ? campaign : undefined
                        };
                }
                
                await analytics.page_view($page.route.id!, utm_tracking);

            } catch (error: any) {
                console.error("Unexpected error logging current page view: ", error);
                // alert(`Error enabling (and logging first view) - ${error.toString()}`);
            }
        }

        // settings_approved = await settings_store.get_setting('approved_analytics');
        // let analytics_enabled = await FirebaseAnalytics.isEnabled();

        // console.log("Analytics state: ", settings_approved, analytics_enabled);

        // const resetAnalyticsData = async () => {
        //     await FirebaseAnalytics.resetAnalyticsData();
        // };
    }

    async function init() {

        // console.log("Capacitor detected: ", Capacitor.getPlatform());
        if (!Capacitor.isNativePlatform()) {
            return;
        }

        /**
         * Deep Linking Support
         */
        initialize_deep_linking_support();

        // console.log("Initialized Capacitor app event handlers!", JSON.stringify(await App.getInfo()));

        initialize_status_bar_support();

        /*
         * Splash Screen Support
         */ 
        hide_splash_screen();

        if (user_logged_in) {

            /*
            * Analytics
            */
            initialize_analytics();

            /*
            * Push Notifications Support
            */            
            initialize_push_notification_support();

            /*
             * Initialize Google Play and Apple App Store products and pricing.
             * NOTE: Exclude 'insiders' as they are always full access.
             */
            let is_purchase_plugin_present = false;
            try {
                is_purchase_plugin_present = CdvPurchase.Platform.GOOGLE_PLAY !== undefined;
            } catch (error) {
                console.error("Unable to determine if plugin 'CdvPurchase' is available: ", error);
            }

            if (is_purchase_plugin_present) {

                if (user_settings!.is_insider === false) {

                    let platform;
                    if (is_android) {
                        platform = CdvPurchase.Platform.GOOGLE_PLAY;
                    } else if (is_ios) {
                        platform = CdvPurchase.Platform.APPLE_APPSTORE;
                    }

                    if (platform && user_settings) {
                        client_subscription_service.initialize(platform, user_settings);
                    }
                }
            }
        }

        // console.log("Plugin ready [App]: ", Capacitor.isPluginAvailable("app"), Capacitor.isPluginAvailable("App"));
        // console.log("Plugin ready [StatusBar]: ", Capacitor.isPluginAvailable("status-bar"), Capacitor.isPluginAvailable("StatusBar"));
        // console.log("Plugin ready [PushNotifications]: ", Capacitor.isPluginAvailable("push-notifications"), Capacitor.isPluginAvailable("PushNotifications"));
        // console.log("Plugin ready [Camera]: ", Capacitor.isPluginAvailable("camera"), Capacitor.isPluginAvailable("Camera"));

        // console.log("Done bootstrapping native support...");
    }

    onMount(() => {
        
        /**
         * Kick-off init
         */
        let initialized = false;
        if (!initialized && browser) {
            initialized = true;
            init();
        }
    });
    
</script>
