<script lang="ts">
	import { show_particles } from '$lib/stores/particles_store';

	import { particlesInit } from '@tsparticles/svelte';
	import { onDestroy, onMount } from 'svelte';
	import { loadFull } from 'tsparticles'; // if you are going to use `loadFull`, install the "tsparticles" package too.
	// import { loadSlim } from '@tsparticles/slim'; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.

	let ParticlesComponent: any;
	let visible = false;

	onMount(async () => {
		const module = await import('@tsparticles/svelte');

		ParticlesComponent = module.default;
	});

	let unsubscribe = show_particles.subscribe((value) => {

        visible = value.visible;
		if (value.duration !== undefined) {
			let duration = value.duration;
			setTimeout(() => {
				visible = false;
			}, duration);
		}
	});

	onDestroy(unsubscribe);

	let confetti_config: any = {
		fullScreen: {
			zIndex: 1000
		},
		particles: {
			color: {
				value: ['#C026D3', '#4A044E', '#F5D0FE']
			},
			move: {
				direction: 'bottom',
				enable: true,
				outModes: {
					default: 'out'
				},
				size: true,
				speed: {
					min: 1,
					max: 2
				}
			},
			number: {
				value: 1000,
				density: {
					enable: true,
					area: 800
				}
			},
			opacity: {
				value: 1,
				animation: {
					enable: false,
					startValue: 'max',
					destroy: 'min',
					speed: 0.3,
					sync: true
				}
			},
			rotate: {
				value: {
					min: 0,
					max: 360
				},
				direction: 'random',
				move: true,
				animation: {
					enable: true,
					speed: 30
				}
			},
			tilt: {
				direction: 'random',
				enable: true,
				move: true,
				value: {
					min: 0,
					max: 360
				},
				animation: {
					enable: true,
					speed: 50
				}
			},
			shape: {
				type: [
					// "circle",
					// "star",
					'image'
				],
				options: {
					image: [
						{
							src: '/logo/swimerize-icon-light-bg.svg',
							width: 8,
							height: 8,
							particles: {
								size: {
									value: {
										min: 2,
										max: 6
									}
								}
							}
						}
					],
					circle: [
						{
							width: 2,
							height: 2,
							particles: {
								size: {
									value: {
										min: 1,
										max: 3
									}
								}
							}
						}
					]
				}
			},
			size: {
				value: {
					min: 1,
					max: 4
				}
			},
			roll: {
				darken: {
					enable: true,
					value: 30
				},
				enlighten: {
					enable: true,
					value: 30
				},
				enable: true,
				speed: {
					min: 15,
					max: 55
				}
			},
			wobble: {
				distance: 15,
				enable: true,
				move: true,
				speed: {
					min: -25,
					max: 25
				}
			}
		}
	};

	let onParticlesLoaded = (event: any) => {
		const particlesContainer = event.detail.particles;

		// you can use particlesContainer to call all the Container class
		// (from the core library) methods like play, pause, refresh, start, stop
	};

	void particlesInit(async (engine) => {
		// call this once per app
		// you can use main to customize the tsParticles instance adding presets or custom shapes
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		//await loadFull(main);

		engine.addConfig(confetti_config);

		await loadFull(engine);
	});
</script>

{#if visible}
	<svelte:component
		this={ParticlesComponent}
		id="tsparticles"
		class="w-[100dvw] h-[100dvh] z-[1000] bg-transparent fixed top-0 pointer-events-none"
		style=""
		options={confetti_config}
		on:particlesLoaded={onParticlesLoaded}
	/>
{/if}
