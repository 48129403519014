<script lang="ts">
	import { show_subscribe_popup } from '$lib/stores/subscribe_popup_store';
    import { Drawer, CloseButton, Radio } from 'flowbite-svelte';
    import { sineIn } from 'svelte/easing';
	import Button from '../Button.svelte';
	import SubscriptionFeaturesTable from './SubscriptionFeaturesTable.svelte';
	import { client_subscription_service } from '$lib/client/subscription_utils';
	import { subscription_store, type SubscriptionStoreEventType } from '$lib/stores/subscription_products_store';
	import Icon from '../Icon.svelte';
	import { onDestroy } from 'svelte';
	import { notification_store } from '$lib/stores/notification_store';
	import LogoActive from '../LogoActive.svelte';
	import { user_session } from '$lib/stores/user_session_store';
	import { show_particles } from '$lib/stores/particles_store';

    let selected_product_id: string | undefined = undefined;
    let selected_offer_id: string | undefined = undefined;
    let features_table_hidden = true;
    let base_plans_hidden = true;
    let purchase_animation_hidden = true;

    let user_session_store = user_session();
    let {user_settings} = $user_session_store;

    let is_paying = user_settings?.subscription_status !== 'freemium';
    $: {
        features_table_hidden = $show_subscribe_popup === false;
        // console.log("Subscription store updated. ", features_table_hidden, base_plans_hidden);

        if (is_paying) {
            features_table_hidden = true;
            base_plans_hidden = true;
            purchase_animation_hidden = true;
        }

        // FOR TESTING
        // purchase_animation_hidden = false;
        // purchase_lifecycle = 'complete_purchase';
    }

    type AnimationPopupEventType = Extract<SubscriptionStoreEventType, 'approved_transaction' | 'complete_purchase' | 'purchase'>;
    let purchase_lifecycle: AnimationPopupEventType | undefined = undefined; 

    let transitionParamsBottom = {
      y: 320,
      duration: 200,
      easing: sineIn
    };

    let subscription_store_unsubscribe = subscription_store.subscribe(value => {

        // console.log("Popup handling subscription store event: ", JSON.stringify(value));

        // ensure products initialized
        if (selected_product_id === undefined) {

            for (let product of value.state.products) {

                // console.log("Subscription popup products initialized: ", value.event_type, product.product_id, product.offer_id);

                if (product.billing_period === 'yearly') {
                    selected_product_id = product.product_id;
                    selected_offer_id = product.offer_id;
                    break;
                }
            }
        }

        // ignore if user is already paying
        if (is_paying) {
            return;
        }

        if (value.event_type === 'error') {

            console.error("Subscription store error: ", value.error);

            if (value.payment_cancelled_by_user) {

                close_all();
                return;
            }
        }

        /*
         * Don't show any UI if lifecycle events not initiated by user (system may be doing bookkeeping),
         * UNLESS the subscription was purchased offline and we have re-enabled the subscription.
         */
        if (value.is_purchasing_product === false && value.event_type !== 'complete_purchase') {
            return;
        }

        // console.log("Subscription store updated: ", value.event_type);

        if (value.initialized === false) {
            return;
        }

        if (value.event_type === 'unverified') {
            
            subscription_store.reset_state();
            close_all();

        } else if (value.event_type === 'error') {
        
            notification_store.notify_message('Error', `Unable to process subscription. ${value.error?.message}`);
            close_all();

        } else if (value.event_type === 'purchase'
                   || value.event_type === 'approved_transaction'
                   || value.event_type === 'complete_purchase') {

            // console.log("Subscription store updated - triggered event: ", value.event_type);
            
            purchase_lifecycle = value.event_type;

            features_table_hidden = true;
            base_plans_hidden = true;
            purchase_animation_hidden = false;

            if (purchase_lifecycle === 'complete_purchase') {

                $show_particles={type: 'confetti', visible: true};
            }
        }

        // console.log("Subscription store updated: ", JSON.stringify(value));
    });

    async function reload_page() {

        // $show_subscribe_popup = false;
        // base_plans_hidden = true;
        // purchase_animation_hidden = true;
        window.location.reload();
    }

    onDestroy(subscription_store_unsubscribe);

    function close_all() {
        show_subscribe_popup.set(false);
        base_plans_hidden = true;
        purchase_animation_hidden = true;
    }

    async function show_available_base_plans() {
        show_subscribe_popup.set(false);
        base_plans_hidden = false;
        purchase_animation_hidden = true;
    }

    async function select_product(product_id: string, offer_id: string) {

        selected_product_id = product_id;
        selected_offer_id = offer_id;
    }

    async function subscribe() {
        
        // client_subscription_service.subscribe();
        if (!selected_product_id || !selected_offer_id) {
            throw new Error("Product or offer not selected!");
        }

        // console.log("Subscribed: ", selected_product_id, selected_offer_id);
        client_subscription_service.subscribe(selected_product_id, selected_offer_id);
    }

  </script>

<Drawer placement="bottom"
          width="w-full"
          transitionType="fly"
          transitionParams={transitionParamsBottom}
          activateClickOutside={false}
          bind:hidden={features_table_hidden}
          divClass="overflow-y-auto z-[999] p-4 bg-backdrop-dark rounded-t-3xl text-primary-foreground-enabled max-h-[100vh] max-w-3xl">

    <div class="flex items-center">
      <!-- <h5 id="drawer-label" class="inline-flex items-center mb-4 text-base font-semibold ">
        Subscription Plans
      </h5> -->
      <CloseButton on:click={close_all} />
    </div>

    <div class="pb-12">
        <h5 class="text-lg font-semibold">
            Compare Plans
        </h5>
        <p class="pt-2 text-xs text-gray-400 dark:text-gray-500">
            Subscribe to enjoy full membership benefits.
        </p>
        <div class="flex flex-col place-items-center mt-8 mx-4">

            <Button on_click={show_available_base_plans} class="w-full flex place-content-center">
                <Icon type={'shopping_cart'} class="mr-2 size-4"/>
                <span>Checkout</span>
            </Button>
        </div>
    </div>

    <div class="relative overflow-y-auto h-[55dvh]">

        <SubscriptionFeaturesTable show_price={true} />

    </div>

</Drawer>

<Drawer placement="bottom"
          width="w-full"
          transitionType="fly"
          transitionParams={transitionParamsBottom}
          activateClickOutside={false}
          bind:hidden={base_plans_hidden}
          divClass="overflow-y-auto z-[999] p-4 bg-backdrop-dark rounded-t-3xl text-primary-foreground-enabled max-h-[100vh] max-w-3xl">

    <div class="flex items-center">
      <!-- <h5 id="drawer-label" class="inline-flex items-center mb-4 text-base font-semibold ">
        Subscription Plans
      </h5> -->
      <CloseButton on:click={close_all} />
    </div>

    <div class="pb-12">
        <h5 class="text-lg font-semibold">
            Full Access Subscription Plans
        </h5>
        <p class="pt-2 text-xs text-gray-400 dark:text-gray-500">
            Choose a subscription plan to complete your purchase.
        </p>
        <div class="flex flex-col w-full">

            <!-- <div class="grid grid-cols-2 place-content-between w-[90dvw] my-8">
                {#each $subscription_store.state.products as product}
                    <div class="flex flex-col place-items-center">
                        <div class="text-xs">
                            {product.billing_period === 'yearly' ? 'Yearly' : 'Monthly'}
                        </div>
                        <div class="text-lg font-semibold">
                            {product.price}
                        </div>
                        {#if product.billing_period === 'yearly'}
                            <div class="text-xs font-light text-primary-foreground-disabled">
                                15% off
                            </div>
                        {/if}
                    </div>
                {/each}

            </div> -->
            <div class="grid gap-6 w-full grid-cols-2 my-8">
                {#each $subscription_store.state.products as product} <!-- [{billing_period: 'monthly', price: "$8.99", product_id: '1', offer_id: '1'}, {billing_period: 'yearly', price: "$89.99", product_id: '2', offer_id: '2'}] -->

                    <Radio name="custom"
                            custom={true}
                            checked={product.billing_period === 'yearly'}
                            on:change={() => {select_product(product.product_id, product.offer_id)}}>

                        <div class="relative bg-backdrop-dark inline-flex justify-between items-center p-5 h-full w-full text-primary-foreground-enabled rounded-lg border-2 cursor-pointer border-gray-700 peer-checked:border-primary peer-checked:bg-backdrop-light/10">
                            <div class="">
                                <div class="text-xs">{product.billing_period === 'yearly' ? 'Yearly' : 'Monthly'}</div>
                                <div class="w-full text-lg font-semibold">{product.price}</div>
                                {#if product.billing_period === 'yearly'}
                                    <div class="absolute right-4 -top-3 italic bg-primary px-2 py-1 text-xs font-medium text-primary-foreground-enabled border border-gray-700 rounded-lg">
                                        15% off
                                    </div>
                                {/if}
                            </div>
                        </div>

                    </Radio>
                    <!-- <Radio name="custom" custom>
                    <div for="hosting-big" class="inline-flex justify-between items-center p-5 w-full text-primary-foreground-enabled rounded-lg border cursor-pointer border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary">
                        <div class="block">
                        <div class="w-full text-lg font-semibold">500-1000 MB</div>
                        <div class="w-full">Good for large websites</div>
                        </div>
                    </div>
                    </Radio> -->
                {/each}
            </div>

            <div class="flex flex-col place-items-center mx-4">

                <Button on_click={() => subscribe()} class="w-full flex place-content-center">
                    Purchase
                </Button>

                <div class="text-[0.65rem] font-extralight mt-4">
                    Auto-renewing. 
                    Cancel any time. 
                    <a href="/terms" on:click={close_all} class="font-semibold text-primary">
                        Terms of Use
                    </a>
                </div>

            </div>
            <!-- <Button on_click={() => subscribe('prod_456', 'order_456')} class_override="w-full">
                Subscribe to Premium
            </Button> -->
        </div>

    </div>

</Drawer>

<Drawer placement="bottom"
          width="w-full"
          transitionType="fly"
          transitionParams={transitionParamsBottom}
          activateClickOutside={false}
          bind:hidden={purchase_animation_hidden}
          divClass="overflow-y-auto z-[999] p-4 bg-backdrop-dark rounded-t-3xl text-primary-foreground-enabled max-h-[100vh] max-w-3xl flex place-items-center">

          <!-- h-[50dvh] -->
    <div class="w-full">

        {#if purchase_lifecycle === 'complete_purchase'}
            <div class="flex flex-col place-items-center p-8 space-y-8">

                <img class="size-48 rounded-full animate-rubber-band" 
                     style="animation-iteration-count: 1; animation-delay: 1000; animation-duration: 1500ms"
                     src="/images/celebration/swimmer_celebrating_color_corrected.png"
                     alt="Swimmer celebrating" />
                <!-- <LogoActive icon_class_override="w-16 h-16" animate={false} /> -->
            
                <div class="text-sm flex flex-col place-items-center gap-y-8">
                    <p class="text-lg flex flex-col place-items-center gap-y-2">
                        <span>
                            Congratulations! 
                        </span>
                        <span class="text-xs">
                            Subscription activated
                        </span>
                    </p>
                    
                    <Button enable_spinner={true} spinner_color={'inverse'} on_click={reload_page} class="w-full flex place-content-center gap-x-2">
                        Dive In <span aria-hidden="true">&rarr;</span>
                    </Button>
                </div>
            </div> 
        {:else}
            <div class="animate-pulse-fade-in flex flex-col place-items-center p-8 space-y-2" 
                 style="animation-iteration-count: infinite; animation-duration: 1000ms">

                <LogoActive icon_class_override="w-16 h-16" animate={false} />
            
                <div class="text-sm flex flex-col space-y-8">
                    {#if purchase_lifecycle === 'approved_transaction'}
                        Validating subscription
                    {:else}
                        Purchase Pending
                    {/if}
                </div>
            </div> 
        {/if}
    </div>

</Drawer>